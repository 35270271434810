import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

// Global texture cache
const textureCache = new Map();

function Viewer360({ totalFrames, imageSrc }) {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const frameRef = useRef(1);
  const animationRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const materialRef = useRef(null);
  const sceneRef = useRef(null);
  const meshRef = useRef(null);
  const isPlayingRef = useRef(true);

  useEffect(() => {
    const basePath = imageSrc.substring(0, imageSrc.lastIndexOf('/') + 1);
    let cleanup = () => {};

    async function init() {
      if (!containerRef.current) return;

      // Setup scene if not already set up
      if (!sceneRef.current) {
        const scene = new THREE.Scene();
        sceneRef.current = scene;

        // Load first image to get dimensions and set aspect ratio
        const img = new Image();
        img.src = imageSrc;
        await new Promise((resolve) => {
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            
            // Calculate dimensions to fit container while maintaining aspect ratio
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;
            let width, height;
            
            if (containerWidth / containerHeight > aspectRatio) {
              // Container is wider than image
              height = containerHeight;
              width = height * aspectRatio;
            } else {
              // Container is taller than image
              width = containerWidth;
              height = width / aspectRatio;
            }

            const renderer = new THREE.WebGLRenderer({ 
              alpha: true,
              antialias: true,
              powerPreference: "high-performance"
            });
            
            renderer.setSize(width, height);
            containerRef.current.appendChild(renderer.domElement);
            rendererRef.current = renderer;

            // Create camera with proper aspect ratio
            const camera = new THREE.OrthographicCamera(
              -1 * aspectRatio, 1 * aspectRatio,
              1, -1,
              0, 1
            );
            camera.position.z = 1;

            // Create geometry that matches aspect ratio
            const geometry = new THREE.PlaneGeometry(2 * aspectRatio, 2);
            const material = new THREE.MeshBasicMaterial({
              transparent: true,
              side: THREE.DoubleSide
            });
            materialRef.current = material;

            const mesh = new THREE.Mesh(geometry, material);
            meshRef.current = mesh;
            scene.add(mesh);

            resolve();
          };
        });

        // Handle window resize
        const handleResize = () => {
          if (!containerRef.current || !rendererRef.current || !meshRef.current) return;

          const containerWidth = containerRef.current.clientWidth;
          const containerHeight = containerRef.current.clientHeight;
          const aspectRatio = meshRef.current.geometry.parameters.width / 2;

          let width, height;
          if (containerWidth / containerHeight > aspectRatio) {
            height = containerHeight;
            width = height * aspectRatio;
          } else {
            width = containerWidth;
            height = width / aspectRatio;
          }

          rendererRef.current.setSize(width, height);
        };

        window.addEventListener('resize', handleResize);
        cleanup = () => window.removeEventListener('resize', handleResize);
      }

      // Load textures in parallel
      if (!textureCache.has(basePath)) {
        setIsLoading(true);
        const textures = new Array(totalFrames + 1);
        const loader = new THREE.TextureLoader();
        const loadPromises = [];

        for (let i = 1; i <= totalFrames; i++) {
          const paddedFrame = String(i).padStart(4, '0');
          const imagePath = `${basePath}${paddedFrame}.png`;
          
          loadPromises.push(
            new Promise((resolve) => {
              loader.load(
                imagePath,
                (texture) => {
                  texture.minFilter = THREE.LinearFilter;
                  texture.magFilter = THREE.LinearFilter;
                  textures[i] = texture;
                  setLoadingProgress((prev) => Math.min((prev + (100 / totalFrames)), 100));
                  resolve();
                },
                undefined,
                () => resolve()
              );
            })
          );
        }
        
        await Promise.all(loadPromises);
        textureCache.set(basePath, textures);
      }

      // Reset animation state
      frameRef.current = 1;
      isPlayingRef.current = true;
      setIsLoading(false);

      // Animation function
      function animate() {
        if (isPlayingRef.current) {
          frameRef.current = (frameRef.current % totalFrames) + 0.25;
          const currentFrame = Math.floor(frameRef.current);
          
          const textures = textureCache.get(basePath);
          if (textures?.[currentFrame] && materialRef.current) {
            materialRef.current.map = textures[currentFrame];
            materialRef.current.needsUpdate = true;
          }
          
          const camera = new THREE.OrthographicCamera(
            -1 * meshRef.current.geometry.parameters.width / 2,
            1 * meshRef.current.geometry.parameters.width / 2,
            1, -1,
            0, 1
          );
          camera.position.z = 1;
          
          rendererRef.current.render(sceneRef.current, camera);
        }
        animationRef.current = requestAnimationFrame(animate);
      }

      // Start animation
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      animate();

      // Interaction handlers
      let isDragging = false;
      let previousX = 0;

      function handleStart(x) {
        isDragging = true;
        previousX = x;
        isPlayingRef.current = false;
      }

      function handleMove(x) {
        if (!isDragging) return;
        
        const delta = x - previousX;
        frameRef.current = Math.max(1, Math.min(totalFrames, 
          frameRef.current + Math.sign(delta)));
        
        const currentFrame = Math.floor(frameRef.current);
        const textures = textureCache.get(basePath);
        if (textures?.[currentFrame] && materialRef.current) {
          materialRef.current.map = textures[currentFrame];
          materialRef.current.needsUpdate = true;
          
          const camera = new THREE.OrthographicCamera(
            -1 * meshRef.current.geometry.parameters.width / 2,
            1 * meshRef.current.geometry.parameters.width / 2,
            1, -1,
            0, 1
          );
          camera.position.z = 1;
          
          rendererRef.current.render(sceneRef.current, camera);
        }
        
        previousX = x;
      }

      function handleEnd() {
        isDragging = false;
        isPlayingRef.current = true;
      }

      containerRef.current.addEventListener('mousedown', (e) => handleStart(e.clientX));
      containerRef.current.addEventListener('mousemove', (e) => handleMove(e.clientX));
      containerRef.current.addEventListener('mouseup', handleEnd);
      containerRef.current.addEventListener('mouseleave', handleEnd);
      containerRef.current.addEventListener('touchstart', (e) => handleStart(e.touches[0].clientX));
      containerRef.current.addEventListener('touchmove', (e) => handleMove(e.touches[0].clientX));
      containerRef.current.addEventListener('touchend', handleEnd);
    }

    init();
    return () => cleanup();
  }, [imageSrc, totalFrames]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
      if (meshRef.current?.geometry) {
        meshRef.current.geometry.dispose();
      }
      if (materialRef.current) {
        materialRef.current.dispose();
      }
    };
  }, []);

  return (
    <div 
      ref={containerRef} 
      style={{ 
        width: '100%', 
        height: '100%',
        background: 'transparent',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <div className="loading-bar-container">
              <div 
                className="loading-bar" 
                style={{ width: `${loadingProgress}%` }}
              />
            </div>
            <div className="loading-text">
              Loading {Math.round(loadingProgress)}%
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Viewer360; 
