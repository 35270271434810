import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import ThreeViewer from './threejs/viewer'
import { StlViewer } from 'react-stl-viewer'
import axios from 'axios';
import { HobbyistPayment } from './components/HobbyistPayment';
import Viewer360 from './components/Viewer360'

function AppContent() {
  const [activeTab, setActiveTab] = useState('description');
  const [showAllResearchers, setShowAllResearchers] = useState(false);
  const [researcherData, setResearcherData] = useState([]);
  const [showAllCommercialAccess, setShowAllCommercialAccess] = useState(false);
  const [commercialAccessData, setCommercialAccessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stlError, setStlError] = useState(null);
  const [nftData, setNftData] = useState(null);
  const [nftError, setNftError] = useState(null);
  const [viewerMounted, setViewerMounted] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const [currentView, setCurrentView] = useState('shell');
  const [activeHistoryTab, setActiveHistoryTab] = useState('conservation');
  const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
  const [skullPreviewIndex, setSkullPreviewIndex] = useState(1);

  const cameraRef = useRef();

  const handleStlError = (error) => {
    console.error("Error loading STL file:", error);
    setStlError("Failed to load 3D model. Please try again later.");
  }

  // Add this function near your other handlers
  const handleStripePayment = () => {
    // Replace this URL with your actual Stripe Payment Link
    window.location.href = 'https://buy.stripe.com/test_aEU6qj4jt8Ybbmg6oo';
  };

  useEffect(() => {
    const stlPath = '/Model/Xenoceratops_Skull_Print.stl';
    
    fetch(stlPath)
      .then(response => {
        if (!response.ok) {
          throw new Error('STL file not found');
        }
      })
      .catch(error => {
        console.error("Error checking STL file:", error);
        setStlError("3D model file not found. Please check the file path.");
      });

    // Fetch NFT data from OpenSea API
    const fetchNftData = async () => {
      try {
        const response = await axios.get(
          'https://testnets-api.opensea.io/v2/chain/sepolia/contract/0xecae394ec951d33c16fd7c56c8416ead9351b603/nfts/6',
          {
            headers: {
              'X-API-KEY': 'YOUR_OPENSEA_API_KEY' // Replace with actual API key
            }
          }
        );
        setNftData(response.data.nft);
      } catch (error) {
        console.error("Error fetching NFT data:", error);
        setNftError("Failed to load NFT data. Please try again later.");
      }
    };

    fetchNftData();
  }, []);

  useEffect(() => {
    console.log('Viewer container dimensions:', {
      height: document.querySelector('.viewer-wrapper')?.clientHeight,
      width: document.querySelector('.viewer-wrapper')?.clientWidth
    });
  }, []);

  const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '400px',
  }

  const getImagePath = () => {
    switch(currentView) {
      case 'skull':
        return '/zuul/0001.png';
      case 'tail':
        return '/zuul/tail/0001.png';
      default: // shell
        return '/zuul/shell/0001.png';
    }
  };

  const handleViewLicense = (licenseType) => {
    console.log(`Viewing ${licenseType} license`);
  };

  const handlePurchaseClick = () => {
    setShowPurchaseOptions(true);
  };

  return (
    <div className="app-container">
      <nav className="main-nav">
        <span className="logo">© Research Casting International</span>
        <div className="nav-links">
          <a href="https://landingcontainer-a1obz.kinsta.page/">HOME</a>
          <a href="https://landingcontainer-a1obz.kinsta.page/institutions">INSTITUTIONS</a>
          <a href="https://landingcontainer-a1obz.kinsta.page/collections">FEATURED</a>
          <a href="https://landingcontainer-a1obz.kinsta.page/explore">EXPLORE</a>
          <a href="https://landingcontainer-a1obz.kinsta.page/shop">GIFT SHOP</a>
          <a href="https://landingcontainer-a1obz.kinsta.page/about">ABOUT</a>
        </div>
      </nav>

      <div className="content-wrapper">
        <header className="specimen-header">
          <div className="museum-tag">ROYAL ONTARIO MUSEUM</div>
          <h1 className="specimen-title">ZUUL CRURIVASTATOR</h1>
          <p className="specimen-subtitle">
            LATE CRETACEOUS PERIOD • MONTANA, USA
          </p>
          <p className="specimen-number">ROM 75860</p>
        </header>

        <main className="specimen-content">
          <div className="content-layout">
            {/* Left Column - 3D Viewer */}
            <section className="model-showcase">
              <div className="viewer-layout">
                <div className="view-selector">
                  <button 
                    className={`view-btn ${currentView === 'skull' ? 'active' : ''}`}
                    onClick={() => setCurrentView('skull')}
                  >
                    Skull
                  </button>
                  <button 
                    className={`view-btn ${currentView === 'shell' ? 'active' : ''}`}
                    onClick={() => setCurrentView('shell')}
                  >
                    Shell
                  </button>
                  <button 
                    className={`view-btn ${currentView === 'tail' ? 'active' : ''}`}
                    onClick={() => setCurrentView('tail')}
                  >
                    Tail
                  </button>
                </div>
                <div className="model-container">
                  <div className="viewer-wrapper">
                    <Viewer360
                      imageUrlFormat="0xxx.png"
                      totalFrames={150}
                      imageSrc={getImagePath()}
                      key={currentView}
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* Right Column - Info */}
            <section className="specimen-info">
              <div className="info-tabs">
                <div className="tab-buttons">
                  <button 
                    className={`tab-btn ${activeTab === 'description' ? 'active' : ''}`}
                    onClick={() => setActiveTab('description')}
                  >
                    DESCRIPTION
                  </button>
                  <button 
                    className={`tab-btn ${activeTab === 'metadata' ? 'active' : ''}`}
                    onClick={() => setActiveTab('metadata')}
                  >
                    METADATA
                  </button>
                </div>

                <div className="tab-content">
                  {activeTab === 'description' ? (
                    <div className="description-content">
                      <div className="description-section">
                        <h3>Overview</h3>
                        <p>
                          Zuul crurivastator was a heavily-armoured ankylosaur, named after the fictional monster "Zuul" 
                          from the 1984 film Ghostbusters. The species name means "destroyer of shins," referring to its 
                          massive tail club that could have been used to break the legs of predatory dinosaurs.
                        </p>
                      </div>
                      
                      <div className="description-section">
                        <h3>Preservation</h3>
                        <p>
                          This remarkably complete specimen includes a fully preserved skull and tail club, and is one of 
                          the best-preserved ankylosaurs ever found. The specimen even preserves rare soft tissues and skin 
                          impressions, giving us unprecedented insights into how these armoured dinosaurs looked in life.
                        </p>
                      </div>
                      
                      <div className="description-section">
                        <h3>Discovery</h3>
                        <p>
                          The specimen was discovered in the Judith River Formation of Montana, and represents one of the 
                          most complete and best-preserved ankylosaurid specimens ever found in North America.
                        </p>
                      </div>

                      <div className="description-highlights">
                        <div className="highlight-item">
                          <span className="highlight-number">76.2</span>
                          <span className="highlight-label">Million Years Old</span>
                        </div>
                        <div className="highlight-item">
                          <span className="highlight-number">6m</span>
                          <span className="highlight-label">Body Length</span>
                        </div>
                        <div className="highlight-item">
                          <span className="highlight-number">2,500</span>
                          <span className="highlight-label">Weight in KG</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="metadata-content">
                      <div className="metadata-sections">
                        {/* Classification Section */}
                        <div className="metadata-section">
                          <h3 className="section-title">Classification</h3>
                          <div className="metadata-grid">
                            <div className="metadata-item">
                              <h4>Kingdom</h4>
                              <p>Animalia</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Clade</h4>
                              <p>Dinosauria</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Order</h4>
                              <p>Ornithischia</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Family</h4>
                              <p>Ankylosauridae</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Genus</h4>
                              <p>Zuul</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Species</h4>
                              <p>Z. crurivastator</p>
                            </div>
                          </div>
                        </div>

                        {/* Physical Characteristics */}
                        <div className="metadata-section">
                          <h3 className="section-title">Physical Characteristics</h3>
                          <div className="metadata-grid">
                            <div className="metadata-item">
                              <h4>Body Length</h4>
                              <p>6 meters (20 feet)</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Weight</h4>
                              <p>2,500 kg (5,500 lbs)</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Tail Club Length</h4>
                              <p>~1 meter (3.3 feet)</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Skull Length</h4>
                              <p>50 cm (20 inches)</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Armor</h4>
                              <p>Extensive osteoderms, spikes, and tail club</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Notable Features</h4>
                              <p>Preserved skin impressions, complete skull and tail</p>
                            </div>
                          </div>
                        </div>

                        {/* Temporal & Geographic Data */}
                        <div className="metadata-section">
                          <h3 className="section-title">Time & Location</h3>
                          <div className="metadata-grid">
                            <div className="metadata-item">
                              <h4>Era</h4>
                              <p>Mesozoic</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Period</h4>
                              <p>Late Cretaceous</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Age</h4>
                              <p>76.2-75.2 Million Years Ago</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Formation</h4>
                              <p>Judith River Formation</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Location</h4>
                              <p>Hill County, Montana, USA</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Environment</h4>
                              <p>Coastal plain ecosystem</p>
                            </div>
                          </div>
                        </div>

                        {/* Discovery & Research */}
                        <div className="metadata-section">
                          <h3 className="section-title">Discovery & Research</h3>
                          <div className="metadata-grid">
                            <div className="metadata-item">
                              <h4>Discovery Date</h4>
                              <p>2014</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Description Date</h4>
                              <p>2017</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Discovered By</h4>
                              <p>Commercial fossil collectors</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Named By</h4>
                              <p>Victoria Arbour & David Evans</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Repository</h4>
                              <p>Royal Ontario Museum</p>
                            </div>
                            <div className="metadata-item">
                              <h4>Specimen Number</h4>
                              <p>ROM 75860</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>

          {/* Detailed Information Sections */}
          <section className="detailed-info-sections">
            <div className="section-container">
              {/* Technical Specifications */}
              <div className={`info-section ${expandedSection === 'technical' ? 'expanded' : ''}`}>
                <button 
                  className="section-header" 
                  onClick={() => setExpandedSection(expandedSection === 'technical' ? null : 'technical')}
                >
                  <h2>Technical Specifications</h2>
                  <span className="expand-icon">{expandedSection === 'technical' ? '−' : '+'}</span>
                </button>
                <div className="section-content">
                  <div className="specs-grid">
                    <div className="spec-group">
                      <h3>Scan Details</h3>
                      <ul>
                        <li><span>Scanner Type:</span> Artec Leo</li>
                        <li><span>Resolution:</span> 0.2mm</li>
                        <li><span>Accuracy:</span> Up to 0.1mm</li>
                        <li><span>Texture Resolution:</span> 2.3mp</li>
                      </ul>
                    </div>
                    <div className="spec-group">
                      <h3>File Information</h3>
                      <ul>
                        <li><span>File Format:</span> STL, OBJ, PLY</li>
                        <li><span>Polygon Count:</span> 12.5M triangles</li>
                        <li><span>Texture Maps:</span> Diffuse, Normal, Roughness</li>
                        <li><span>File Size:</span> 1.2GB (compressed)</li>
                      </ul>
                    </div>
                    <div className="spec-group">
                      <h3>Quality Assurance</h3>
                      <ul>
                        <li><span>Verification Method:</span> Photogrammetry Cross-reference</li>
                        <li><span>Accuracy Validation:</span> Physical measurement comparison</li>
                        <li><span>Quality Control:</span> Museum standard verification</li>
                        <li><span>Error Margin:</span> ±0.5mm</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Licensing Information */}
              <div className={`info-section ${expandedSection === 'licensing' ? 'expanded' : ''}`}>
                <button 
                  className="section-header" 
                  onClick={() => setExpandedSection(expandedSection === 'licensing' ? null : 'licensing')}
                >
                  <h2>Licensing Information</h2>
                  <span className="expand-icon">{expandedSection === 'licensing' ? '−' : '+'}</span>
                </button>
                <div className="section-content">
                  <div className="license-grid">
                    <div className="license-type">
                      <h3>Personal Use License</h3>
                      <ul>
                        <li>3D printing for personal collection</li>
                        <li>Educational use</li>
                        <li>Non-commercial study</li>
                        <li>Digital viewing and analysis</li>
                      </ul>
                      <div className="license-overlay">
                        <button className="view-license-btn" onClick={() => handleViewLicense('personal')}>
                          View License
                        </button>
                      </div>
                    </div>
                    <div className="license-type">
                      <h3>Academic License</h3>
                      <ul>
                        <li>Research publications</li>
                        <li>Educational presentations</li>
                        <li>Academic collaboration</li>
                        <li>Institutional display</li>
                      </ul>
                      <div className="license-overlay">
                        <button className="view-license-btn" onClick={() => handleViewLicense('academic')}>
                          View License
                        </button>
                      </div>
                    </div>
                    <div className="license-type">
                      <h3>Commercial License</h3>
                      <ul>
                        <li>Exhibition rights</li>
                        <li>Product development</li>
                        <li>Media production</li>
                        <li>Commercial reproduction</li>
                      </ul>
                      <div className="license-overlay">
                        <button className="view-license-btn" onClick={() => handleViewLicense('commercial')}>
                          View License
                        </button>
                      </div>
                    </div>
                    <div className="license-type">
                      <h3>Collector License</h3>
                      <ul>
                        <li>Verifiable digital ownership</li>
                        <li>High-resolution asset access</li>
                        <li>Exclusive collector benefits</li>
                        <li>Blockchain-verified provenance</li>
                      </ul>
                      <div className="license-overlay">
                        <button className="view-license-btn" onClick={() => handleViewLicense('collector')}>
                          View License
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Conservation History */}
              <div className={`info-section ${expandedSection === 'conservation' ? 'expanded' : ''}`}>
                <button 
                  className="section-header" 
                  onClick={() => setExpandedSection(expandedSection === 'conservation' ? null : 'conservation')}
                >
                  <h2>Provenance</h2>
                  <span className="expand-icon">{expandedSection === 'conservation' ? '−' : '+'}</span>
                </button>
                <div className="section-content">
                  <div className="history-nav">
                    <button 
                      className={`history-tab ${activeHistoryTab === 'conservation' ? 'active' : ''}`}
                      onClick={() => setActiveHistoryTab('conservation')}
                    >
                      Conservation
                    </button>
                    <button 
                      className={`history-tab ${activeHistoryTab === 'research' ? 'active' : ''}`}
                      onClick={() => {
                        setActiveHistoryTab('research');
                      }}
                    >
                      Research
                    </button>
                    <button 
                      className={`history-tab ${activeHistoryTab === 'commercial' ? 'active' : ''}`}
                      onClick={() => {
                        setActiveHistoryTab('commercial');
                      }}
                    >
                      Commercial
                    </button>
                    <button 
                      className={`history-tab ${activeHistoryTab === 'collector' ? 'active' : ''}`}
                      onClick={() => setActiveHistoryTab('collector')}
                    >
                      Collector
                    </button>
                  </div>

                  {activeHistoryTab === 'conservation' && (
                    <div className="timeline">
                      <div className="timeline-entry">
                        <div className="date">2014</div>
                        <div className="details">
                          <h3>Discovery</h3>
                          <p>Specimen excavated from Judith River Formation, Montana</p>
                        </div>
                      </div>
                      <div className="timeline-entry">
                        <div className="date">2015-2016</div>
                        <div className="details">
                          <h3>Preparation</h3>
                          <p>Mechanical preparation and stabilization of fossil material</p>
                        </div>
                      </div>
                      <div className="timeline-entry">
                        <div className="date">2017</div>
                        <div className="details">
                          <h3>Scientific Description</h3>
                          <p>Formal description and naming of Zuul crurivastator</p>
                        </div>
                      </div>
                      <div className="timeline-entry">
                        <div className="date">2023</div>
                        <div className="details">
                          <h3>Digital Preservation</h3>
                          <p>High-resolution 3D scanning and digital archiving</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeHistoryTab === 'research' && (
                    <div className="research-records">
                      {loading ? (
                        <div className="loading">Loading research data...</div>
                      ) : researcherData.length > 0 ? (
                        researcherData.map((researcher, index) => (
                          <div key={index} className="record-entry">
                            <div className="record-header">
                              <h3>{researcher.institution}</h3>
                              <span className="record-date">{researcher.date}</span>
                            </div>
                            <div className="record-details">
                              <p><strong>Researcher:</strong> {researcher.name}</p>
                              <p><strong>Purpose:</strong> {researcher.purpose}</p>
                              <p><strong>Status:</strong> {researcher.status}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-records">No research records available</div>
                      )}
                    </div>
                  )}

                  {activeHistoryTab === 'commercial' && (
                    <div className="commercial-records">
                      {loading ? (
                        <div className="loading">Loading commercial data...</div>
                      ) : commercialAccessData.length > 0 ? (
                        commercialAccessData.map((access, index) => (
                          <div key={index} className="record-entry">
                            <div className="record-header">
                              <h3>{access.company}</h3>
                              <span className="record-date">{access.date}</span>
                            </div>
                            <div className="record-details">
                              <p><strong>License Type:</strong> {access.licenseType}</p>
                              <p><strong>Purpose:</strong> {access.purpose}</p>
                              <p><strong>Status:</strong> {access.status}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-records">No commercial records available</div>
                      )}
                    </div>
                  )}

                  {activeHistoryTab === 'collector' && (
                    <div className="collector-records">
                      <div className="nft-ownership">
                        <div className="nft-info">
                          <div className="nft-header">
                            <h3>Digital Ownership Record</h3>
                            <span className="blockchain-badge">Ethereum</span>
                          </div>
                          <div className="nft-details">
                            <div className="detail-group">
                              <h4>Token Details</h4>
                              <ul>
                                <li><strong>Contract Address:</strong> 0xecae394ec951d33c16fd7c56c8416ead9351b603</li>
                                <li><strong>Token ID:</strong> 6</li>
                                <li><strong>Token Standard:</strong> ERC-721</li>
                                <li><strong>Blockchain:</strong> Ethereum (Sepolia)</li>
                              </ul>
                            </div>
                            <div className="detail-group">
                              <h4>Ownership Rights</h4>
                              <ul>
                                <li>Verifiable digital ownership</li>
                                <li>High-resolution asset access</li>
                                <li>Commercial licensing options</li>
                                <li>Exclusive collector benefits</li>
                              </ul>
                            </div>
                            <div className="detail-group">
                              <h4>Smart Contract Features</h4>
                              <ul>
                                <li>Automated royalty distribution</li>
                                <li>Provenance tracking</li>
                                <li>Transfer restrictions</li>
                                <li>Institutional verification</li>
                              </ul>
                            </div>
                          </div>
                          <div className="nft-actions">
                            <a 
                              href="https://testnets.opensea.io/assets/sepolia/0xecae394ec951d33c16fd7c56c8416ead9351b603/6" 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="opensea-button"
                            >
                              View on OpenSea
                            </a>
                            <a 
                              href={`https://sepolia.etherscan.io/token/0xecae394ec951d33c16fd7c56c8416ead9351b603?a=6`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="etherscan-button"
                            >
                              View on Etherscan
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* Access Tiers Section */}
          <div className="access-tiers-header">
            <h2>Access Digital Assets</h2>
            <p>Choose the access tier that best suits your needs, from personal use to institutional licensing</p>
          </div>

          <section className="access-tiers">
            <div className="tier hobbyist-tier">
              <div className="tier-header">
                <div className="tier-header-content">
                  <h2>Hobbyist</h2>
                  <span className="tier-subtitle">Non-Commercial License</span>
                </div>
                <div className="tier-pricing">
                  <span className="tier-price">$29.99</span>
                  <span className="tier-period">one-time</span>
                </div>
              </div>
              <div className="tier-features">
                <ul>
                  <li>High-quality 3D printable STL file</li>
                  <li>Basic texture maps</li>
                  <li>Assembly instructions</li>
                  <li>Pre-supported available</li>
                  <li>Non-commercial license</li>
                </ul>
              </div>
              <button onClick={handlePurchaseClick} className="tier-cta">
                View Purchase Options
              </button>
            </div>

            <div className="tier collector-tier">
              <div className="tier-header">
                <div className="tier-header-content">
                  <h2>Collector</h2>
                  <span className="tier-subtitle">Digital Ownership</span>
                </div>
                <div className="tier-badge">NFT</div>
              </div>
              <div className="tier-features">
                <ul>
                  <li>Museum-grade 3D files</li>
                  <li>High-resolution textures</li>
                  <li>Scientific documentation</li>
                  <li>Ownership verification</li>
                  <li>Commercial rights available</li>
                </ul>
              </div>
              <a 
                href="https://testnets.opensea.io/assets/sepolia/0xecae394ec951d33c16fd7c56c8416ead9351b603/6"
                target="_blank"
                rel="noopener noreferrer"
                className="tier-cta collector"
              >
                View on OpenSea
              </a>
            </div>

            <div className="tier research-tier">
              <div className="tier-header">
                <div className="tier-header-content">
                  <h2>Research</h2>
                  <span className="tier-subtitle">Academic Access</span>
                </div>
                <div className="tier-badge academic">Academic</div>
              </div>
              <div className="tier-features">
                <ul>
                  <li>High-resolution scan data</li>
                  <li>Complete measurement data</li>
                  <li>Research documentation</li>
                  <li>Academic collaboration</li>
                  <li>Blockchain verification</li>
                </ul>
              </div>
              <button className="tier-cta research">
              </button>
            </div>

            <div className="tier commercial-tier">
              <div className="tier-header">
                <div className="tier-header-content">
                  <h2>Commercial</h2>
                  <span className="tier-subtitle">Enterprise License</span>
                </div>
                <div className="tier-badge enterprise">Enterprise</div>
              </div>
              <div className="tier-features">
                <ul>
                  <li>Commercial license</li>
                  <li>Exhibition rights</li>
                  <li>Marketing materials</li>
                  <li>Technical support</li>
                  <li>Custom solutions</li>
                </ul>
              </div>
              <button className="tier-cta commercial">
              </button>
            </div>
          </section>

          {showPurchaseOptions && (
            <section className="purchase-options-overlay">
              <div className="purchase-options-content">
                <div className="purchase-header">
                  <h2>Available Digital Assets</h2>
                  <button className="close-button" onClick={() => setShowPurchaseOptions(false)}>×</button>
                </div>
                
                <div className="purchase-sections">
                  <div className="section-label">3D Scan Files</div>
                  <div className="purchase-grid">
                    <div className="purchase-item">
                      <div className="item-image skull-preview">
                        <img 
                          src={`/forSale/skull/${skullPreviewIndex}.png`} 
                          alt={`Skull preview ${skullPreviewIndex}`}
                        />
                        <button 
                          className="preview-nav prev" 
                          onClick={handleSkullPreviewPrev}
                          aria-label="Previous image"
                        >
                          ‹
                        </button>
                        <button 
                          className="preview-nav next" 
                          onClick={handleSkullPreviewNext}
                          aria-label="Next image"
                        >
                          ›
                        </button>
                      </div>
                      <div className="item-details">
                        <h3>Skull Model</h3>
                        <p>High-quality skull scan with texture maps</p>
                        <span className="price">$14.99</span>
                        <button className="purchase-btn" onClick={handleStripePayment}>Purchase</button>
                      </div>
                    </div>
                    
                    <div className="purchase-item">
                      <div className="item-image shell-preview"></div>
                      <div className="item-details">
                        <h3>Shell Model</h3>
                        <p>Detailed shell scan with armor plates</p>
                        <span className="price">$14.99</span>
                        <button className="purchase-btn" onClick={handleStripePayment}>Purchase</button>
                      </div>
                    </div>
                    
                    <div className="purchase-item">
                      <div className="item-image tail-preview"></div>
                      <div className="item-details">
                        <h3>Tail Model</h3>
                        <p>Complete tail club scan</p>
                        <span className="price">$14.99</span>
                        <button className="purchase-btn" onClick={handleStripePayment}>Purchase</button>
                      </div>
                    </div>
                    
                    <div className="purchase-item featured">
                      <div className="item-image full-preview"></div>
                      <div className="item-details">
                        <h3>Complete Model</h3>
                        <p>Full specimen scan with all components</p>
                        <div className="savings-badge">Save 25%</div>
                        <span className="price">$29.99</span>
                        <button className="purchase-btn" onClick={handleStripePayment}>Purchase</button>
                      </div>
                    </div>
                  </div>

                  <div className="section-label">Marketplace Items</div>
                  <div className="purchase-grid">
                    <div className="purchase-item premium">
                      <div className="item-image bronze-preview"></div>
                      <div className="item-details">
                        <h3>Bronze Cast Skull</h3>
                        <p>Museum-quality bronze casting</p>
                        <span className="price">$2,499.99</span>
                        <button className="purchase-btn">Contact Sales</button>
                      </div>
                    </div>
                    
                    <div className="purchase-item">
                      <div className="item-image print-preview"></div>
                      <div className="item-details">
                        <h3>3D Printed Model</h3>
                        <p>Professional grade 1:10 scale model</p>
                        <span className="price">$299.99</span>
                        <button className="purchase-btn">Pre-order</button>
                      </div>
                    </div>
                    
                    <div className="purchase-item">
                      <div className="item-image kit-preview"></div>
                      <div className="item-details">
                        <h3>Educational Kit</h3>
                        <p>Teaching materials and scale model</p>
                        <span className="price">$199.99</span>
                        <button className="purchase-btn">Pre-order</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Access Records Section */}
          <section className="access-records">
            {/* ... keep existing commercial and researcher sections ... */}
          </section>
        </main>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
